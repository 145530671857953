<template>
	<v-row align="center" justify="center">
		<v-col cols="12" sm="8" md="6" lg="5" xl="4">
			<v-card shaped
				class="mx-auto"
				:max-width="mainShapedMaxWidth"
				:loading="formLoading" :disabled="formLoading"
			>
				<v-card-title class="pl-1 pt-1">
					<!-- <span>{{ currentTitle }}</span> -->
					<v-card flat color="transparent">
						<v-img
							width="180"
							:src="require('@/assets/logo_globaldesk.svg')"
						></v-img>
					</v-card>
				</v-card-title>
				<!-- text-center -->
				<v-card class="px-md-4 pt-6" flat align="center">
					<!-- class="mb-4" -->
					<!-- height="90" -->
					<v-card flat color="transparent">
						<v-img
							width="150"
							:src="require('@/assets/logo_gavcom_150_90.png')"
						></v-img>
					</v-card>
					<!-- :src="step == 1 ?  globalDeskLogo: gavcomLogo" -->
				</v-card>

				<v-form ref="LoginForm">
					<v-window v-model="step" touchless>
						<v-window-item :value="1">
							<v-card-text>
								<h3 class="title font-weight-light text-center">
									Acceso al sistema
								</h3>
								<v-row no-gutters justify="center" class="mb-4">
									<v-col cols="12" sm="10" md="8">
										<base-text-field autofocus v-model="editedItem.user" label="Usuario" maxlength="100" 
											prepend-icon="mdi-account"
											:rules="[commonValRules.required]" required
										></base-text-field>
										<base-text-field 
											v-model="editedItem.password"
											label="Contraseña"
											prepend-icon="mdi-lock"
											:append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
											:type="showPwd ? 'text' : 'password'"
											maxlength="25"
											@click:append="showPwd = !showPwd"
											:rules="[commonValRules.required]"
											required
											@keypress.enter="login()"
										></base-text-field>
									</v-col>
								</v-row>

								<span class="caption grey--text text--darken-1">
									<!-- ¿No tiene una cuenta <strong>GLOBAL DESK</strong>? <router-link class="info--text" :to="{ name: 'Registro' }"> Crear cuenta</router-link><br> -->
									<!-- ¿Aún no está registrado al sistema <strong>GAVCOM</strong>? <router-link class="info--text" :to="{ name: 'Registro' }"> Registrarme</router-link><br> -->
									<!-- ¿No puede acceder al sistema? <router-link class="info--text" :to="{ name: 'SendVerificationEmail' }"> Ver opciones de acceso</router-link><br> -->
									¿No puede acceder al sistema? <br>
									<router-link class="info--text" :to="{ name: 'SendVerificationEmail' }"> Enviar correo de verificación</router-link> | 
									<router-link class="info--text" :to="{ name: 'ForgotPassword' }">¿Olvidé mi contraseña?</router-link>
								</span>
							</v-card-text>
						</v-window-item>

						<!-- <v-window-item :value="2">
							<v-card-text>
								
								
								<span class="caption grey--text text--darken-1">
									Ingrese la contraseña para la cuenta <strong>{{editedItem.user}}</strong><br>
									<router-link class="info--text" :to="{ name: 'ForgotPassword' }">¿Olvidó su contraseña?</router-link>
								</span>
							</v-card-text>
						</v-window-item> -->

						<!-- <v-window-item :value="1">
							<v-card-text>
								<base-text-field autofocus class="mb-4" v-model="editedItem.user" label="Correo electrónico o código de usuario" maxlength="100" 
									:rules="[commonValRules.required, validationRules.userRules.counter]" required @keypress.enter="next()"
								></base-text-field>

								<span class="caption grey--text text--darken-1">
									¿No tiene una cuenta? <router-link class="info--text" :to="{ name: 'Registro' }"> Registrarme</router-link><br>
									¿No puede acceder a su cuenta? <router-link class="info--text" :to="{ name: 'SendVerificationEmail' }"> Enviar correo de verificación</router-link>
								</span>
							</v-card-text>
						</v-window-item>

						<v-window-item :value="2">
							<v-card-text>
								<base-text-field autofocus class="mb-4"
									v-model="editedItem.password"
									label="Contraseña"
									:append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
									:type="showPwd ? 'text' : 'password'"
									maxlength="25"
									@click:append="showPwd = !showPwd"
									:rules="[commonValRules.required, validationRules.passwordRules.counter]"
									required
									@keypress.enter="login()"
								></base-text-field>
								
								<span class="caption grey--text text--darken-1">
									Ingrese la contraseña para la cuenta <strong>{{editedItem.user}}</strong><br>
									<router-link class="info--text" :to="{ name: 'ForgotPassword' }">¿Olvidó su contraseña?</router-link>
								</span>
							</v-card-text>
						</v-window-item> -->

						<v-window-item :value="2">
							<v-card-text class="text-center">
								<h4 class="grey--text">¡Gracias por confiar en nuestro equipo de trabajo!</h4>
								<div class="grey--text text--darken-1 mb-4">
									Seleccione una empresa para continuar
								</div>
								<v-item-group
									v-model="selectedCompany"
									mandatory
									@change="changeSelectedCompany"
								>
									<v-row justify="center">
										<v-col v-for="(item, i) in companies"
											:key="i"
											cols="6"
											md="6"
										>
											<v-item v-slot="{ active, toggle }">
												<v-card @click="toggle" class="pa-4"
													:elevation="active ? 8 : 0"
												>
														<!-- <v-img v-if="!!item.logo" :src="item.logo"
															aspect-ratio="1.5"
															contain
														>
														</v-img> -->
														<v-icon class="mb-2" size="50">mdi-office-building-outline</v-icon>
														<br>
														<v-icon class="mb-2" color="primary" size="30"> 
															{{ active ? 'mdi-checkbox-marked-circle' : 'mdi-checkbox-marked-circle-outline' }}
														</v-icon>
														<h4>{{item.visualName}}</h4>
												</v-card>
											</v-item>
										</v-col>
									</v-row>
								</v-item-group>
							</v-card-text>
						</v-window-item>

						<v-window-item :value="3">
							<v-card-text class="text-center">
								<h4 class="grey--text">¡Gracias por confiar en nuestro equipo de trabajo!</h4>
								<div class="grey--text text--darken-1 mb-4">
									Seleccione una sucursal para continuar
								</div>
								<v-item-group
									v-model="selectedBranchOffice"
									mandatory
									@change="changeSelectedBranchOffice"
								>
									<v-row justify="center">
										<v-col v-for="(item, i) in branchOffices"
											:key="i"
											cols="6"
											sm="6"
											md="6"
											lg="4"
										>
											<v-item v-slot="{ active, toggle }">
												<v-card @click="toggle" class="pa-4"
													:elevation="active ? 8 : 0"
												>
													<v-icon class="mb-2" size="50">{{item.main ? 'mdi-office-building' : 'mdi-home-city'}}</v-icon>
													<br>
													<v-icon class="mb-2" :color="active ? 'primary' : 'grey'" size="30"> 
														{{ active ? 'mdi-checkbox-marked-circle' : 'mdi-checkbox-marked-circle-outline' }}
													</v-icon>
													<h4>{{item.visualName}}</h4>
												</v-card>
											</v-item>
										</v-col>
									</v-row>
								</v-item-group>
							</v-card-text>
						</v-window-item>
					</v-window>
				</v-form>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn rounded
						v-if="step == 1"
						color="primary"
						depressed
						@click="login()"
						:loading="formLoading"
					>
						<v-icon size="25" left>mdi-login</v-icon>
						Ingresar
					</v-btn>
					<v-btn rounded
						v-else-if="step == 2"
						color="primary"
						depressed
						@click="loginToSelectedCompany()"
						:loading="formLoading"
					>
						<v-icon size="25" left>mdi-check</v-icon>
						Seleccionar
					</v-btn>
					<v-btn rounded
						v-else-if="step == 3"
						color="primary"
						depressed
						@click="loginToSelectedBranch()"
						:loading="formLoading"
					>
						<v-icon size="25" left>mdi-check</v-icon>
						Seleccionar
					</v-btn>
				</v-card-actions>

				<!-- <v-card-actions>
					<v-btn v-if="step === 2"
						:disabled="step === 1"
						text
						@click="back()"
					>
						Atras
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn
						v-if="step < 2"
						color="primary"
						depressed
						@click="next()"
					>
						Siguiente
					</v-btn>
					<v-btn
						v-else-if="step == 2"
						color="primary"
						depressed
						@click="login()"
						:loading="formLoading"
					>
						Acceder
					</v-btn>
					<v-btn
						v-else-if="step == 3"
						color="primary"
						depressed
						@click="loginToSelectedCompany()"
						:loading="formLoading"
					>
						Seleccionar
					</v-btn>
					<v-btn
						v-else
						color="primary"
						depressed
						@click="loginToSelectedBranch()"
						:loading="formLoading"
					>
						Seleccionar
					</v-btn>
				</v-card-actions> -->
			</v-card>
		</v-col>
		
		<base-loader />
	</v-row>
</template>

<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'
import { mapActions } from 'vuex'


export default {
	mixins: [MainExternalData],

	data() {
		return {
			step: 1,
			showPwd: false,
			formLoading: false,
			editedItem: {
				user:'',
				password:'',
				companyId:'',
				branchOfficeId:'',
			},
			defaultItem: {
				user:'',
				password:'',
				companyId:'',
				branchOfficeId:'',
			},
			selectedCompany: 0,
			defaultCompanyLogo: require("@/assets/company/default-logo.png"),
			companies:[
				// { id: 1, visualName: 'Nueva empresa con nombre largo', logo: ''},
				// { id: 2, visualName: 'Nueva empresa con nombre largo', logo: ''}
			],
			selectedBranchOffice: 0,
			branchOffices:[
				// { id: 1, visualName: 'Sucursal villa el carmen', main: false},
				// { id: 2, visualName: 'Casa matriz', main: true}
			],
			validationRules: {
				userRules: {
					counter: value => (value.length >= 3 && value.length <= 100) || 'No debe tener más de 100 caracteres, ni menos de 3 caracteres.',
        },

				passwordRules: {
					counter: value => (value.length <= 25) || 'No debe tener más de 25 caracteres.',
        },
			}
		}
	},

	computed: {
		// currentTitle () {
		// 	switch (this.step) {
		// 		case 1: return 'Iniciar sesión'
		// 		// case 2: return 'Ingresar contraseña'
		// 		case 2: return 'Seleccione una empresa'
		// 		case 3: return 'Seleccione una sucursal'
		// 		default: return ''
		// 	}
		// },

		mainShapedMaxWidth(){
			return this.step == 1 ? 500 : 1500;
		}

	},

	created() {
	},

	methods: {
		...mapActions(['saveTemporaryToken', 'removeTemporaryToken', 'saveToken']),
		// back() {
		// 	if (this.step===2) {
		// 		this.editedItem.password='';
		// 	}
		// 	this.step--
		// },

		// next(){
		// 	if (!this.editedItem.user || this.editedItem.user.trim() === '') {
		// 		return;
		// 	}
		// 	this.step++
		// },

		async login() {
			let me=this;
			if (!me.$refs.LoginForm.validate()) {
				return;
			}
			try {
				me.SHOW_LOADING()
				me.formLoading = true
				let request= {
					'user': me.editedItem.user,
					'password': me.editedItem.password,
				}
				let data = await me.getObjectResponseWithoutHeaders('api/Account/Login', request);
				if (data === undefined) {
					return;
				}

				switch (data.next) {
					case 0:
						me.saveToken(data.token);
						break;
					case 1:
						me.saveTemporaryToken(data.token);
						await me.choiceCompanies();
						break;
					case 2:
						me.saveTemporaryToken(data.token);
						await me.choiceBranchOffices();
						break;
				}
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.formLoading = false
      }
		},

		clean(){
			this.editedItem = Object.assign({}, this.defaultItem);
			this.branchOffices = [];
			this.$refs.LoginForm.resetValidation();
			this.step=1;
			this.showPwd=false;
    },

		async choiceCompanies() {
			let me = this;
			if (await me.getAssignedCompanies()) {
				me.step = 2;
			}
			else {
				me.removeTemporaryToken();
				me.clean();
			}
		},

		async getAssignedCompanies() {
      let me=this;
			// var companiesArray=[];
			let request = {
				'person': me.baseRequest.person
			};
			return await me.getObjectResponse('api/User/GetCompaniesAssigned', request).then(data => {
				if (data === undefined) {
					return false;
				}
				// companiesArray = data.companies; 
				// companiesArray.map(function(x){
				// 	me.companies.push({text:x.visualName, value:x.id});
				// });
				me.companies = data.companies;
				me.changeSelectedCompany(me.selectedCompany);
				return true;
			}).catch(function(error){
				me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				return false;
			});
		},

		changeSelectedCompany(index) {
			let me = this;
			me.editedItem.companyId = me.companies[index].id;
		},

		async loginToSelectedCompany() {
			let me=this;
			if (!me.$refs.LoginForm.validate()) {
				return;
			}

			try {
				me.SHOW_LOADING()
				me.formLoading = true
				let request= {
					'token': me.token,
					'companyId': me.editedItem.companyId,
				}
				let data = await me.getObjectResponse('api/Account/LoginToSelectedCompany', request);
				if (data === undefined) {
					return;
				}

				if (data.next) {
					me.saveTemporaryToken(data.token);
					await me.choiceBranchOffices();
				}
				else {
					me.saveToken(data.token)
				}
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.formLoading = false
      }
		},

		async choiceBranchOffices() {
			let me = this;
			if (await me.getAssignedBranchOffices()) {
				me.step = 3;
			}
			else {
				me.removeTemporaryToken();
				me.clean();
			}
		},

		async getAssignedBranchOffices() {
      let me=this;
			// var branchOfficesArray=[];
			return await me.getObjectResponse('api/UserBranchOffice/GetAssignedBranchOffices', me.baseRequest).then(data => {
				if (data !== undefined) {
					// branchOfficesArray = data.branchOffices; 
					// branchOfficesArray.map(function(x){
					// 	me.branchOffices.push({text:x.visualName, value:x.id});
					// });
					me.branchOffices = data.branchOffices;
					me.changeSelectedBranchOffice(me.selectedBranchOffice);
					return true;
				}
			}).catch(function(error){
				me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				return false;
			});
		},

		changeSelectedBranchOffice(index) {
			let me = this;
			me.editedItem.branchOfficeId = me.branchOffices[index].id;
		},

		async loginToSelectedBranch() {
			let me=this;
			if (!me.$refs.LoginForm.validate()) {
				return;
			}

			try {
				me.SHOW_LOADING()
				me.formLoading = true
				let request= {
					'token': me.token,
					'branchOfficeId': me.editedItem.branchOfficeId,
				}
				await me.getObjectResponse('api/Account/LoginToSelectedBranch', request).then(data => {
					if (data == undefined) {
						return;
					}
					me.saveToken(data.token);
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.formLoading = false
      }
		},
	},
}
</script>